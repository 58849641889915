body {
  text-align: center; /* Center the page for IE */
  background-color: #099; /* Default background color */
  color: #fff;
  font-size: 25px;
  font-family: sans-serif;
}

#body {
  position: relative; /* Keeps other absolute elemetns inside*/
  width: 704px; /* the width I want + the border*/
  margin: 0 auto; /*user for centering */
  text-align: left; /*user for centering */
}

#header {
  float: right; /* Move to the right */
  font-size: 40px;
  padding-bottom: 10px;
}

#box {
  clear: both; /* keep the header above the content*/
  height: 500px; /* set the height */
  border: 2px solid #fff; /* set the border */
}

#link-box {
  min-width: 30%;
  height: 500px;
  position: relative;
  float: right;
  overflow: hidden;
}

#link-box #transparency {
  opacity: 0.5;
  filter: alpha(opacity = 50);
  -moz-opacity: 0.5;
  background-color: #252525;
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

#social-links,
#local-links {
  position: relative;
  list-style-type: none;
  padding: 10px;
  margin: 0;
  font-size: 20px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#local-links {
  position: absolute;
  bottom: 0;
}

#footer {
  padding-top: 10px;
}

#social-links img,
#local-links img {
  vertical-align: middle;
  margin-bottom: 5px;
  border: 0;
}
