body {
  text-align: center;
  color: #fff;
  background-color: #099;
  font-family: sans-serif;
  font-size: 25px;
}

#body {
  text-align: left;
  width: 704px;
  margin: 0 auto;
  position: relative;
}

#header {
  float: right;
  padding-bottom: 10px;
  font-size: 40px;
}

#box {
  clear: both;
  border: 2px solid #fff;
  height: 500px;
}

#link-box {
  float: right;
  min-width: 30%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

#link-box #transparency {
  opacity: .5;
  filter: alpha(opacity= 50);
  -moz-opacity: .5;
  background-color: #252525;
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

#social-links, #local-links {
  margin: 0;
  padding: 10px;
  font-size: 20px;
  list-style-type: none;
  position: relative;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#local-links {
  position: absolute;
  bottom: 0;
}

#footer {
  padding-top: 10px;
}

#social-links img, #local-links img {
  vertical-align: middle;
  border: 0;
  margin-bottom: 5px;
}
/*# sourceMappingURL=index.5ae73ed4.css.map */
